import './assets/css/settingsZH.css'
import './assets/css/reset.css'
import './assets/css/base.css'
import './i18n.ts'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { GlobalContextProvider } from './components/GlobalContext.tsx'
import { LoadingView } from './components/LoadingView.tsx'

// eslint-disable-next-line react-refresh/only-export-components
const Transcription = React.lazy(() => import('./components/Transcription.tsx'))

const router = createBrowserRouter([{ path: '/*', element: <Transcription /> }])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Suspense fallback={<LoadingView />}>
        <RouterProvider router={router} />
      </Suspense>
    </GlobalContextProvider>
  </React.StrictMode>,
)
